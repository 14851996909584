import logo from "./logo.svg";
import "./App.css";
import React, {useEffect} from "react";

function App() {
  useEffect(() => {
    adjustBoxHeights();
  }, []); 

  const adjustBoxHeights = () => {
    const boxes = document.querySelectorAll('.content-inner-box');
    let maxHeight = 0;

    boxes.forEach(box => {
      const height = box.offsetHeight;
      
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    boxes.forEach(box => {
      box.style.height = `calc(${maxHeight}px - 3rem)`;
    });
  };

  return (
    <div className="App">
      
      <header className="header">
        <div className="logo">
          <img src="./soarus_logo.jpg" alt="Logo" />
        </div>
      </header>
      <body>
        <div className="content-top bacground-gray">
          <div className="content-container">
            <div className="font-title">SOARUS</div>

            <div className="font-subtitle">
              솔러스는 당신과 함께 성장합니다.
            </div>
            <div className="font-content">
              프로그램 제작, 운영 그리고 온라인 마케팅을 해요.<br/>
              웹과 앱 그리고 다양한 응용프로그램을 개발하고 또 이를 운영 중
              이에요. <br />고객사를 위한 컨텐츠 제작, SNS 채널 운영, 광고 집행 등의
              온라인 마케팅을 하고 있어요.
              
            </div>
          </div>
        </div>
        <div className="content bacground-white">
          <div className="content-container">
            <div className="font-title2">업무분야</div>
            <div className="font-subtitle2">솔러스의 업무분야를 소개합니다.</div>

            <div className="content-inner-frame">
              <div className="content-inner-box">
                <div className="box-inner-title">프로그램 개발, 운영</div>
                <div className="box-inner-content">
                  웹, 앱, 응용프로그램 등의 프로그램을 개발해요. <br/>
                  SNS 채널 분석, 업무자동화 등 마케팅 분석에 도움이 되는 유틸 프로그램도 개발하여 
                  온라인 마케팅 분야의 데이터를 수집하고 가공하는데도 공들이고 있어요.<br/>
                  
                  </div>
              </div>
              <div className="content-inner-box">
              <div className="box-inner-title">프로그램 운영</div>
                <div className="box-inner-content">
                  종종 떠오르는 아이디어로 도파민이 넘쳐나는 재미있는 앱을 직접 만들어서 운영하고 있어요.<br/>
                  업무의 범위가 늘어날 수록 늘어나는 아이디어에 운영 예정인 앱 또한 쌓여가고 있어요.
                  </div>
              </div>
              <div className="content-inner-box">
              <div className="box-inner-title">온라인 마케팅</div>
                <div className="box-inner-content">
                  고객의 데이터를 분석하여 가장 적합한 마케팅에 대해 컨설팅하고, 마케팅을 진행해요.<br/>
                  브랜딩, SNS 채널운영, 컨텐츠 제작, 바이럴 마케팅, 검색광고 등 여러 분야의 마케팅 하고 있어 고객 맞춤형으로 계획하고 진행할 수 있어요.
                  </div>
              </div>


            </div>
            
          </div>
        </div>

        <div className="content bacground-gray">
          <div className="content-container">
          <div className="font-title2">이야기</div>
            <div className="font-subtitle2">솔러스의 생각을 소개합니다.</div>
          </div>
          <div className="content-container">
            <img src="./soarus_1x1.jpg"  alt=""/>
            <div class='font-subtitle2'>
              솔러스는 "함께 성장"을 기본으로 철칙으로 삼고 일하고 있어요.<br/>
              솔러스와 솔러스의 구성원이 함께 성장하는 것을 이야기 하는것과 동시에<br/>
              솔러스와 고객사가 함께 성장하는 것을 의미한답니다.
            </div>
            <img src="./data.png"  alt=""/>
            <div class='font-subtitle2'>
              우리는 데이터를 기반으로 이야기 하는 것을 좋아해요.<br/>
              느낌과 감 보다는 철저한 데이터 분석을 통해 결과를 예측해보고 진행해요.<br/>
              이는 우리의 일이 난관을 최소화하고 성공의 방향으로 빠르게 이끄는데 큰 도움이되요.
              
            </div>
            <img src="./lab.png"  alt=""/>
            <div class='font-subtitle2'>
              솔러스는 새로운 분야를 연구하고 확장하고 있어요.<br/>
              실험적인 업무는 솔러스 자체적으로 진행하고 성공적인 결과를 확인하고 나면<br/>
              이 업무는 솔러스의 새로운 업무 분야가 된답니다.<br/>
              무한히 성장하는 솔러스를 지켜봐 주세요! 
            </div>
          </div>
          <div className="content-container">
          </div>

        </div>
      </body>
    </div>
  );
}

export default App;
